.cmNotesTextarea{
	height: 120px !important;
}
.lastMonthStatusBadge{
	display: flex;
	justify-content: center;
	align-items: center;
}
.contractorUploadInvoiceForm{
	label{
		font-size: 0.8rem;
	}
	input:disabled, select:disabled{
        background-color: rgb(220, 220, 220);
    }
}
@media only screen and (min-width: 600px){
	/*Big smartphones [426px -> 600px]*/
	.fileUploadSubmitCol{
		margin-top: -55px;
	}
}
.shadowBox {
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 15px;
	box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.03);
}