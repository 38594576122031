.full-cont {
	width: 100%;
	height: 100vh;
	color: $main-red;
}
.searchCandidateInput{
    display: flex;
}
.linkedinProfileThumb{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.loginLinkedinBtn{
	img{
		max-width: 120px;
		width: 25px;
		margin-right: 10px;
	}
	cursor: pointer;
	border: 1px solid $linkedin-blue;
	padding: 10px;
	
}
.actionBtnSpan{
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:hover{
        color: $main-red;
    }   
}
.showMoreBtnLogin{
	font-size: 0.7rem;
	cursor: pointer;
	&:hover{
		color: $main-red;
	}
}
.nimblLink{
	background-image: linear-gradient(
		to right,
		$main-red,
		$main-red 50%,
		#6c757d 50%
  	);
	background-size: 200% 100%;
	background-position: -100%;
	-webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	&:hover{
		background-position: 0;
		&::before{
			width: 100%;
		}
	}
	&:before {
		content: '';
		background: $main-red;
		display: block;
		position: absolute;
		bottom: -3px;
		left: 0;
		width: 0;
		height: 3px;
	}
}
.upsert-contractor-card{
	border-left: 1px solid #f3f4f7;;
}
.mainContractorTable, .mainContractorMonthTable{
    font-size: 0.8rem;
    table tbody td, table th{
        padding: 0.55rem;
    }
    .totalPayConverted{
        width: 80px;
    }
	.number-of-hours-col{
		min-width: 70px;
	}
	.paymentMethodCell{
		width: 100px;
	}
	.contractorInactive{
		background-color:#FFCDD2;
	}
	.contractorAlmostInactive{
		background-color:#FFFFCD;
	}
}
.photoCol{
	width: 50px;
}
.invisibleText{
	opacity: 0;
}
input#isProforma{
	width: 20px;
	height: 20px;
}
.upsertContractorForm, .changeContractForm, .referralFormPage, #clientInvoiceViewerForm {
    input:disabled, select:disabled, textarea:disabled {
        // background-color: rgb(220, 220, 220);
		color: #6c757d;
		background-color: #f3f4f7;
    }
}
.upsertContractorForm{
	label {
		margin-bottom: 0;
	}
}
.clientCurrencyBadge{
	position: relative;
	span.deleteBadgeSpan {
		position: absolute;
		top: -7.5px;
		right: -7.5px;
		vertical-align:auto;
		background-color: red;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		cursor: pointer;
		span {
			font-size: 12px;
		}
	}
}
.tooltipTriggerElement{
	z-index: 1072;
}
.customTooltip{
	display: block;
	position: fixed;
	overflow: hidden;
	background-color: #e0e3eb;
	color: #4a4b5a;
	font-size: 0.8rem;
	border-radius: 5px;
	padding: 2px 5px;
}
.clickable {
	cursor: pointer;
}
.form-group-check{
	display: flex;
	flex-direction: column;
	.checkbox-cont {
		input{
			width: 20px;
			height: 20px;
		}
	}
}

.paidCell{
	background-color:#dcedc9;
}
.paleRedCell{
	background-color: #ffcccb;
}
.preparedCell{
	background-color:#FFFFCD;
}
.addressDividerRow {
	border-top: 1px solid #f3f4f7;;
	// border-color: #4a4b5a;
}
.revolutBtn {
	background-color: #e0e3eb;
	color: black;
	.revolutLogo {
		width: 20px;
		height: 20px;
	}
}
.revolutSpan{
	.revolutLogo{
		width: 15px;
		height: 15px;
	}
}
.all-unset{
	all: unset;
}
.revolut-batch-cell-fail{
	background-color: #ffcccb;
}
.payment-progress-bar{
	height: 20px;
	font-size: 1rem;
	font-weight: bold;
}
.underline{
	text-decoration: underline;
	font-weight: bold;
}
.filter-invert-img{
	filter: invert(1);
}
.revolut-action-btn{
	display: block;
    padding: 0.5rem 0.75rem;
	border-radius: 0.3rem;
	border: 1px solid transparent;
	border-color: #25c2e3;
}
.gen-paperwork-url{
	cursor: pointer;
	font-weight: bold;
	&:hover{
		color: $main-red;
	}
}
.flex-1{
	flex: 1;
}
table.client-paperwork-table{
	.client-name{
		font-weight: bolder;
		color: rgb(83, 80, 248);
	}
}
.font-size-sm{
	font-size: 0.7rem;
}
.iframeHolder{
	height: 865px;
}
.uploaded-signature-img{
	width: 150px;
	height: 90px;
	border: 1px solid $main-red;
	object-fit: contain;
}
.signature-text{
	font-family: 'Herr Von', cursive;
	font-size: 70px;
	display: inline-block;
}
.signature-preview-img-cont{
	width: 300px;
	height: 150px;
	border: 1px dashed $main-red;
	overflow: hidden;
}
.signature-preview-img{
	max-width: 300px;
	max-height: 150px;
}

.commentBtn{
    position: relative;
}
.notiComment{
    color: white;
    font-size: 0.6rem;
    text-align: center;

    display: inline-block;
    position: absolute;
    top: -4px;
    right: -11px;

    width: 15px;
    height: 15px;

    background-color: $main-red;
    border-radius: 40%;
}
.notiComment.has3{
	width: 22px;
}
.richTextEditor{
	border: 1px solid #e2e7f1;
	// 1px solid #e2e7f1
	height: 250px;
}
.job-description-viewer{
	border: 1px solid #e2e7f1;
	padding: 5px;
}


.gsi-material-button {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-appearance: none;
	background-color: WHITE;
	background-image: none;
	border: 1px solid #747775;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #1f1f1f;
	cursor: pointer;
	font-family: 'Roboto', arial, sans-serif;
	font-size: 14px;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
	transition: background-color .218s, border-color .218s, box-shadow .218s;
	vertical-align: middle;
	white-space: nowrap;
	width: auto;
	max-width: 400px;
	min-width: min-content;
}
  
.gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
}
  
.gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: space-between;
	position: relative;
	width: 100%;
}
  
.gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	font-family: 'Roboto', arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}
  
.gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity .218s;
	transition: opacity .218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}
  
.gsi-material-button:disabled {
	cursor: default;
	background-color: #ffffff61;
	border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: #303030;
	opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: #303030;
	opacity: 8%;
}
.approvedHoursCont{
	color: white;
	background-color: green;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.side-nav-item{
	position: relative;
	.notificationBubble{
		background-color: $main-red;
		color: white;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		display: block;
		position: absolute;
		top: 10px;
		right: 20px;
		border: 1px solid #a1a1a1;
		font-size: 10px;
		text-align: center;
	}
}